var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', {
    attrs: {
      "title": _vm.$t('views.Setting.title')
    }
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.settings.length > 0 && _vm.isReport ? _c('div', [_c('v-subheader', [_vm._v(_vm._s(_vm.$t("views.Setting.subheader")))]), _vm._l(_vm.settings, function (item, index) {
    return _c('v-list', {
      key: index,
      attrs: {
        "subheader": "",
        "three-line": ""
      }
    }, [_c('v-list-item', [_c('v-list-item-action', [_c('v-switch', {
      attrs: {
        "inset": "",
        "input-value": item.get,
        "disabled": !item.isEnabled
      },
      on: {
        "change": item.set
      }
    })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t(item.title)) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t(item.subtitle)) + " "), item.link ? _c('v-btn', {
      attrs: {
        "small": "",
        "icon": "",
        "href": item.link,
        "target": "_blank"
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-information-outline")])], 1) : _vm._e()], 1)], 1)], 1), index < _vm.settings.length - 1 ? _c('v-divider') : _vm._e()], 1);
  })], 2) : _vm._e(), _vm.isShowingThgFeatureSettingsList && _vm.thgSettings.length > 0 ? _c('div', [_c('v-subheader', [_vm._v(_vm._s(_vm.$t("views.Setting.thg.subheader")))]), _vm._l(_vm.thgSettings, function (item, index) {
    return _c('v-list', {
      key: 'thg' + index,
      attrs: {
        "subheader": "",
        "three-line": ""
      }
    }, [_c('v-list-item', [_c('v-list-item-action', [_c('v-switch', {
      attrs: {
        "inset": "",
        "input-value": item.get,
        "disabled": !item.isEnabled
      },
      on: {
        "change": item.set
      }
    })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t(item.title)) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t(item.subtitle)) + " "), item.link ? _c('v-btn', {
      attrs: {
        "small": "",
        "icon": "",
        "href": item.link,
        "target": "_blank"
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-information-outline")])], 1) : _vm._e()], 1)], 1)], 1), index < _vm.thgSettings.length - 1 ? _c('v-divider') : _vm._e()], 1);
  })], 2) : _vm._e(), _vm.isAuthenticated && _vm.isReportPortal && _vm.partnerSettings.length > 0 ? _c('div', [_c('v-subheader', [_vm._v(_vm._s(_vm.$t("views.Setting.report.subheader")))]), _vm._l(_vm.partnerSettings, function (item, index) {
    return _c('v-list', {
      key: 'partner' + index,
      attrs: {
        "subheader": "",
        "three-line": ""
      }
    }, [_c('v-list-item', [_c('v-list-item-action', [_c('v-switch', {
      attrs: {
        "inset": "",
        "input-value": item.get,
        "disabled": !item.isEnabled || item.isDisabled
      },
      on: {
        "change": item.set
      }
    }), !item.isEnabled ? _c('v-icon', {
      staticClass: "mt-n2",
      attrs: {
        "color": "warning"
      }
    }, [_vm._v("mdi-lock")]) : _vm._e()], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t(item.title)) + " ")]), !item.isEnabled ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("views.Setting.premium")) + " ")]) : _vm._e(), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t(item.subtitle)) + " "), item.link ? _c('v-btn', {
      attrs: {
        "small": "",
        "icon": "",
        "href": item.link,
        "target": "_blank"
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-information-outline")])], 1) : _vm._e()], 1)], 1)], 1), index < _vm.partnerSettings.length - 1 ? _c('v-divider') : _vm._e()], 1);
  })], 2) : _vm._e(), _vm.isAuthenticated && _vm.adminSettings.length > 0 ? _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.READ,
      "a": _vm.ResourceEnum.ADMIN,
      "field": _vm.partnerId
    }
  }, [_c('v-subheader', [_vm._v(_vm._s(_vm.$t("views.Setting.admin.subheader")))]), _vm._l(_vm.adminSettings, function (item, index) {
    return _c('v-list', {
      key: 'admin' + index,
      attrs: {
        "subheader": "",
        "three-line": ""
      }
    }, [[_c('v-list-item', [_c('v-list-item-action', [_c('v-switch', {
      attrs: {
        "inset": "",
        "input-value": item.get,
        "disabled": !item.isEnabled
      },
      on: {
        "change": item.set
      }
    })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t(item.title)) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t(item.subtitle)) + " "), item.link ? _c('v-btn', {
      attrs: {
        "small": "",
        "icon": "",
        "href": item.link,
        "target": "_blank"
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-information-outline")])], 1) : _vm._e()], 1)], 1)], 1), _c('v-divider')]], 2);
  }), _c('v-list-item', {
    on: {
      "click": _vm.generateError
    }
  }, [_c('v-list-item-action', [_c('v-btn', {
    staticClass: "mr-3",
    attrs: {
      "disabled": "",
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-bug-outline")])], 1)], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Generate Error")]), _c('v-list-item-subtitle', [_vm._v("Creates an error to debug the ErrorModule.")])], 1)], 1)], 2) : _vm._e(), _c('small', [_vm._v(_vm._s(_vm.$t("views.Setting.appVersion")) + ": " + _vm._s(_vm.version))])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }